<template>
    <div class="mt-2">
        <h5 class="text-center">Eventos Clínicos</h5>
        <CButton v-if="!usersView" @click="addEvent" color="primary">Nuevo Evento</CButton>

        <div v-show="eventForm">
        <CRow class="mt-4">
            <CCol col="6">
                <label class="label">Motivo de Consulta</label>
                <CTextarea :disabled="disabled" v-model="currentEvent.consultation_reason" height="150" placeholder="Motivo de Consulta"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Anamnesis</label>
                <CTextarea :disabled="disabled" v-model="currentEvent.next_anamnesis" placeholder="Anamnesis"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Evaluación</label>
                <div class="evaluacion">
                <CTextarea :disabled="disabled" v-model="currentEvent.evaluation" placeholder="Evaluación"></CTextarea>
                </div>
            </CCol>
            <CCol col="6">
                <label class="label">Indicaciones</label>
                <CTextarea :disabled="disabled" v-model="currentEvent.indications" placeholder="Indicaciones"></CTextarea>
            </CCol>
            <CCol col="6" v-show="!disabled">
                <label>Diagnóstico</label>
                <v-select
                multiple
                ref="examenselect"
                class="my-1 w-100 custom-v-select-iduam"
                :placeholder="placeholder_diagnosis"
                label="Buscar Diagnóstico"
                :options="optionsDiagnosis" 
                @search="searchD"
                 @input="updateDiagnosisId">
                <template slot="selected-option" slot-scope="option">
                  <span :style="[option.type == 'category' ? {marginLeft: 0, fontWeight:700} : {marginLeft: '16px'}]">{{ option.label }}</span>
                </template>
                <template slot="option" slot-scope="option">
                    <span :style="[option.type == 'category' ? {marginLeft: 0, fontWeight:700} : {marginLeft: '16px'}]">{{  option.label }}</span>
                </template>
              </v-select>

<!--             <CListGroup v-if="!searchLoader && search_diagnosis" style="overflow-y: scroll;max-height: 186px;">
                <CListGroupItem @click="togglerDiagnosis(diagnosis)" :color="selectedDiagnosis.includes(diagnosis.id) ? 'dark' : null" href="#" v-for="diagnosis in optionsDiagnosis" :key="diagnosis.id" style="padding: 6px;">
                    <p style="margin-bottom: 0;">{{diagnosis.text}}</p>
                </CListGroupItem>
                <CListGroupItem v-if="noresults">
                    <p>No se encontraron resultados</p>
                    </CListGroupItem>
                </CListGroup> -->
            </CCol>
            <CCol col="6" v-if="disabled">
                <label>Diagnóstico</label>
                <CTextarea :disabled="disabled" v-model="diagnosis_descriptions" placeholder="Diagnóstico"></CTextarea>
            </CCol>
                <CCol col="6">
                    <label class="label">Descripción de Diagnóstico</label>
                    <CTextarea :disabled="disabled" v-model="currentEvent.diagnosis_description" placeholder="Descripción de Diagnóstico"></CTextarea>
                </CCol>
        </CRow> 
        
        <div v-if="!disabled">
        <CButton @click="createUserClinicalEvent" color="primary" :disabled="!currentEvent.consultation_reason">
            <CSpinner v-if="loadingBtn" color="white" size="sm" />
            <p v-else class="mb-0">Guardar</p>
        </CButton>
        <CButton @click="eventForm = false;" v-if="eventForm" class="ml-1" color="secondary">
            Cancelar
        </CButton>
        <CAlert style="margin-bottom: 0" v-if="alertSuccess" color="success">Registro guardado correctamente</CAlert>
        </div>

        </div>

        <CDataTable
            class="mt-2"
            hover
            striped
            :items="events"
            :fields="fields"
            :items-per-page="pagination.perPage"
            :pagination="pagination"
            index-columnS
            v-if="!loading"
        >
                <template #diagnosis="data" >
                    <td v-if="data.item.diagnosis && data.item.diagnosis.length">
                        <p v-for="diagnosis in data.item.diagnosis" :key="diagnosis.id">{{diagnosis?.diagnosis?.description}}</p>
                    </td>
                    <td v-else>
                        --
                    </td>
                </template>
                <template #created_at="data" >
                    <td>{{data.item.created_at}}</td>
                </template>
                <template #action="data">
                    <td>

                    <div style="height: 100%; display:flex; align-items:center;">
                    <CButton
                        v-c-tooltip="'Ver Evento Clínico'"
                        color="info"
                        size="sm"
                        class="m-1"
                        @click="setCurrentEvent(data.item)"
                    >
                        <font-awesome-icon icon="eye" />
                    </CButton>
                    </div>
                    </td>
                </template>
                </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="primary"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>

        <CPagination
            :active-page.sync="currentPage"
            :pages="Math.ceil(total / pagination.perPage)"
            align="center"
            size="lg"
        />
    </div>
</template>

<script>
import _ from 'lodash/lodash'
import { getClinicalEvents, getDiagnosis, createUserClinicalEvent } from '../../api/atenciones'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    props: ['user_id', 'attention_id', 'usersView'],
    components: {'v-select': vSelect},
    data () {
        return {
            loading: true,
            placeholder_diagnosis: '',
            disabled: false,
            alertSuccess:false,
            loadingBtn: false,
            currentPage: 1,
            total: null,
            pagination: {
            page: 1,
            perPage: 10,
            },
            events: [],
            eventForm: false,
            currentEvent: {
                consultation_reason: '',
                next_anamnesis: '',
                evaluation: '',
                diagnosis_cie10_array: [],
                diagnosis_description: '',
                indications: ''
            },
            diagnosis_list: [],
            search_diagnosis: '',
            searchLoader: false,
            optionsDiagnosis: [],
            selectedDiagnosis: [],
            fields: [
            {
              key: "consultation_reason",
              label: "Motivo de Consulta",
              _props: { scope: "col" },
            },
            {
              key: "diagnosis",
              label: "Diagnósitico",
              _props: { scope: "col" },
            },
            {
              key: "created_at",
              label: "Fecha",
              _props: { scope: "col" },
            },
            {
              key: "action",
              label: "Detalle",
              _props: { scope: "col" },
            },
      ],    
        }
    },
    methods: {
        searchD(search, loading) {
            loading(true)
            this.search_diagnosis = search
            if (this.search_diagnosis.length > 2) {
                const data = {search: this.search_diagnosis, page: 1, limit: 10};
                getDiagnosis(data).then(res => {
                    let optionsDiagnosis = [];

                    res.diagnosis.forEach(diagnosis => {
                        let internalDiagnoses = [];

                        if (Array.isArray(diagnosis.diagnosis)) {
                            diagnosis.diagnosis.forEach(innerDiagnosis => {
                                internalDiagnoses.push({ label: innerDiagnosis.description, value: innerDiagnosis.id, type: 'diagnosis' });
                            });
                        }

                        const mainDiagnosisObj = { label: diagnosis.description, value: diagnosis.id, type: 'category' };
                        const combinedDiagnoses = [mainDiagnosisObj, ...internalDiagnoses];

                        optionsDiagnosis.push(...combinedDiagnoses);
                    });

                        this.optionsDiagnosis = optionsDiagnosis
                        loading(false)
                        this.$refs.examenselect.search = ""
                        this.placeholder_diagnosis = search
                        this.searchLoader = false
                        this.noresults = !res.data.length ? true : false
                    })
            } else {
                loading(false)
                this.searchLoader = false
            }
        },
        addEvent() {
            this.disabled = false
            this.currentEvent = {
                consultation_reason: '',
                next_anamnesis: '',
                evaluation: '',
                diagnosis_cie10_array: [],
                diagnosis_description: '',
                indications: ''
            }
            this.eventForm = true;
        },
        getClinicalEvents(data) {
            this.loading = true
            getClinicalEvents(data).then(res => {
                this.loading = false
                this.events = res.medical_records
                this.total = res.total
            })
        },
        searchDiagnosis() {

    },
    updateDiagnosisId(selectedItems) {
        console.log(selectedItems);
        const diagnosisIds = selectedItems.map(item => item.value);
        this.currentEvent.diagnosis_cie10_array = selectedItems.map(item => {
            return {id: item.value, type: item.type}
        });
    },
    togglerDiagnosis(diagnosis) {
      if (this.selectedDiagnosis.includes(diagnosis.id)) {
        this.selectedDiagnosis = this.selectedDiagnosis.filter(userid => userid !== diagnosis.id)
        this.optionsDiagnosis = this.optionsDiagnosis.map(user => {
          return {...user, disabled: this.selectedDiagnosis.includes(selectedDiagnosis.id)}
        })
      } else {
        this.selectedDiagnosis.push(diagnosis.id)
        this.optionsDiagnosis = this.optionsDiagnosis.map(diagnosis => {
          return {...diagnosis, disabled: this.selectedDiagnosis.includes(diagnosis.id)}
        })
      }
    },
    createUserClinicalEvent() {
        const data = {...this.currentEvent}
        data.user_id = this.user_id
        data.attention_id = this.attention_id
        this.loadingBtn = true;
        createUserClinicalEvent(data).then(res => {
            this.loadingBtn = false;
            this.events = res
            const data = {page: this.pagination.page, limit: this.pagination.perPage, user_id: this.user_id}
            this.getClinicalEvents(data)
            this.alertSuccess = true
            this.currentEvent = {
                consultation_reason: '',
                next_anamnesis: '',
                evaluation: '',
                diagnosis_cie10_array: '',
                diagnosis_description: '',
                indications: ''
            }
                this.$refs.examenselect.clearSelection()
            setTimeout(() => {
                this.alertSuccess = false
            }, 1000);
        }).catch(e => {
            this.loadingBtn = false;
        })
    },
    setCurrentEvent(event) {
        this.eventForm = true
        this.disabled = true
        this.currentEvent = event
        this.diagnosis_descriptions = event.diagnosis.map(item => item.diagnosis.description).join(', ')
    }
    },
        watch: {
    currentPage: _.debounce(function (page) {
    this.pagination = {
        page,
        perPage: this.pagination.perPage,
    };
        const data = {page: this.page, limit: this.perPage, user_id: this.user_id}
        this.getClinicalEvents(data)
    }, 500),
/*         search_diagnosis: _.debounce(function (value) {
      this.searchDiagnosis()
    }, 1000), */
    },
    mounted () {
        const data = {page: this.pagination.page, limit: this.pagination.perPage, user_id: this.user_id}
        this.getClinicalEvents(data)
    },
}
</script>

<style lang="scss" scoped>
.evaluacion textarea.form-control {
    min-height: 140px !important;
}
</style>