<template>
    <main style="width: 100%;">
        <CButton
            v-if="checkPermissions(['edit_user_app'])"
            color="primary"
            size="sm"
            class="mb-3"
            @click="editUser = !editUser"
            style="display:flex; gap: 8px; align-items:center;"
        >
        {{!editUser ? 'Habilitar Edición' : 'Desactivar Edición'}}<font-awesome-icon icon="user-edit" />
        </CButton>
        <div class="datos-usuario" v-show="!editUser">
            <p class="mb-0"><span style="font-weight: 500;">Nombre: </span>{{pacienteData?.name}}</p>
            <p class="mb-0"><span style="font-weight: 500;">RUT: </span>{{pacienteData?.info?.rut}}</p>
            <p v-if="pacienteData?.info?.nacimiento" class="mb-0"><span style="font-weight: 500;">Fecha de Nacimiento: </span>{{pacienteData?.info?.nacimiento | filterDate}}</p>
            <p v-else class="mb-0"><span style="font-weight: 500;">Fecha de Nacimiento: </span>--</p>
            <p class="mb-0"><span style="font-weight: 500;">Email: </span>{{pacienteData?.email}}</p>
            <p class="mb-0"><span style="font-weight: 500;">Teléfono: </span>{{pacienteData?.info?.telefono}}</p>
            <p class="mb-0"><span style="font-weight: 500;">País: </span>{{countryName(pacienteData?.info?.pais)}}</p>
            <p class="mb-0"><span style="font-weight: 500;">Región: </span>{{regionName(pacienteData?.info?.region)}}</p>
            <p class="mb-0"><span style="font-weight: 500;">Comuna: </span>{{pacienteData?.info?.comuna_data?.comuna}}</p>
            <p class="mb-0"><span style="font-weight: 500;">Dirección: </span>{{pacienteData?.info?.direccion}}</p>
            <p v-if="pacienteData?.info?.nacimiento" class="mb-0"><span style="font-weight: 500;">Fecha nacimiento: </span>{{pacienteData?.info?.nacimiento}} ({{pacienteData?.info?.nacimiento | age}} años)</p>
            <p v-if="pacienteData?.info?.genero" class="mb-0"><span style="font-weight: 500;">Género: </span>{{pacienteData?.info?.genero === 1 ? 'Masculino':'Femenino'}}</p>
            <p v-else class="mb-0"><span style="font-weight: 500;">Género: </span>--</p>
        </div>

        <div v-show="editUser">
              <CForm>
                <CRow>
                <CCol>
                    <label>Nombre:</label>
                <CInput
                  placeholder="Nombre"
                  v-model='pacienteEdit.name'
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <label>Rut:</label>
                <CInput
                  placeholder="Rut"
                  v-model='pacienteEdit.rut'
                  @keyup="(rut) => {formatRut(pacienteEdit.rut)}"
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                    <label>Email:</label>
                <CInput
                    placeholder="Email"
                    v-model='pacienteEdit.email'
                     @input="validateEmail"
                    >
                    <template #prepend-content><CIcon name="cil-envelope-closed"/></template>
                </CInput>
                <label>Teléfono</label>
                <CInput
                  placeholder="Teléfono"
                  v-model='pacienteEdit.telefono'
                  maxlength="9"
                >
                  <template #prepend-content><font-awesome-icon icon="phone" />  +56</template>
                </CInput>
                <label>Dirección</label>
                <CInput
                    placeholder="Dirección"
                    v-model='pacienteEdit.direccion'
                >
                    <template #prepend-content><font-awesome-icon icon="map-marked-alt" /></template>
                </CInput>

                <label>Género</label>
                <v-select :options="generos" label="name" :reduce="data => data.id" v-model="pacienteEdit.genero"></v-select>

                <label class="pt-2 mb-0">Fecha de Nacimiento: </label>
                    <datetime
                        width="100%"
                        class="pl-1 my-2 datepickers__datepicker"
                        type="date"
                        v-model="pacienteEdit.nacimiento"
                        :value-zone="Intl.DateTimeFormat().resolvedOptions().timeZone"
                    ></datetime>
                </CCol>

                <CCol>
                <label>Seleccione País:</label>
                <v-select style="margin-bottom: 1rem;" :disabled="true" :options="paises" label="name" :reduce="data => data.id" v-model="pacienteEdit.pais"></v-select>

                <label>Seleccione Región:</label>
                <v-select style="margin-bottom: 1rem;" :options="regiones" label="region" :reduce="data => data.id" v-model="pacienteEdit.region"></v-select>

                <label>Seleccione Comuna:</label>
                <v-select style="margin-bottom: 1rem;" :options="comunas" label="comuna" :reduce="data => data.id" v-model="pacienteEdit.comuna"></v-select>

                <label>Seleccione Cuadrante:</label>
                <v-select style="margin-bottom: 1rem;" :options="cuadrantes" label="nombre" :reduce="data => data.id" v-model="pacienteEdit.cuadrante_id"></v-select>
                
                <label>Cluster de Usuario:</label>
                <v-select style="margin-bottom: 1rem;" multiple :options="clusters" label="name" :reduce="data => data.id" v-model="pacienteEdit.cluster"></v-select>
                </CCol>
                </CRow>

                <div style="display:flex; align-items:center;">
                    <CButton @click="updateUserInfoById" class="mt-2" color="success"><CSpinner color="white" size="sm" v-if="loader"/> Guardar</CButton>
                    <div v-if="errors.length">
                        <ul>
                            <li v-for="error in errors" :key="error">* {{ error }}</li>
                        </ul>
                    </div>
                </div>
              </CForm>
        </div>
    </main>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { validate, clean, format } from 'rut.js'
import moment from 'moment'
import { getCuadrantes, getPaisesActivos, getRegionesActivas, getComunasActivas } from '../../api/cuadrantes'
import { updateUserInfoById } from '../../api/user'
import checkPermissions from '../../helpers/userPermissions'


export default {
    props: ['pacienteData', 'getUserData', 'user_id'],
    components: {'v-select': vSelect},
    data () {
        return {
            editUser: false,
            pacienteEdit: {},
            clusters: [
                {id: 1, name: 'Mujeres'},
                {id: 2, name: 'Comercio'},
                {id: 3, name: 'Hogar'}
            ],
            generos: [
                {id: 1, name: 'Masculino'},
                {id: 2, name: 'Femenino'},
            ],
            cuadrantes: [],
            paises: [],
            regiones: [],
            comunas: [],
            validRut: null,
            errors: [],
            loader: false
        }
    },
    methods: {
        checkPermissions,
        countryName(id) {
            const country = this.paises.find(country => country.id === id);
            return country.name
        },
        regionName(id) {
            const region = this.regiones.find(region => region.id === id);
            return region.region
        },
        validateEmail() {
            this.errors = [];
            if (!this.validEmail(this.pacienteEdit.email)) {
                this.errors.push('Dirección de correo electrónico inválida.');
            }
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        getCuadrantes(){
            const data = {
                page: 1,
                limit: 9999999
            }

            getCuadrantes(data).then(res => {
                this.cuadrantes = res.data
            }).catch(e => {
                console.log(e);
            })
        },
        getPaisesActivos() {
            getPaisesActivos().then(res => {
                this.paises = res.paises
            })
        },
        getRegionesActivas(pais_id) {
            getRegionesActivas({pais_id}).then(res => {
                this.regiones = res.regiones
            })
        },
        getComunasActivas(region_id) {
            getComunasActivas({region_id}).then(res => {
                this.comunas = res.comunas
                if (region_id !== this.pacienteData?.info?.region) {
                    this.pacienteEdit.comuna = ''
                } else {
                    this.pacienteEdit.comuna = this.pacienteData?.info?.comuna
                }
            })
        },
        formatRut(rut) {
            this.pacienteEdit.rut = format(rut)  
            this.validRut = validate(this.pacienteEdit.rut)
        },
        updateUserInfoById() {
            this.loader = true
            const data = {...this.pacienteEdit}
            data.nacimiento = moment(data.nacimiento).format('YYYY-MM-DD')
            data.rut = data.rut.replace(/\./g, '');
            updateUserInfoById(data).then(res => {
                this.loader = false
                this.getUserData(this.user_id)
                this.editUser = false;
            }).catch(e => {
                this.loader = false
                console.log(e);
            })
        }
    },
    filters: {
        filterDate: function(date){
            return moment(date).format("DD-MM-YYYY")
        },
        age: function(date){
            const birthdate = moment(date);
            return moment().diff(birthdate, 'years');
        },
    },
    watch: {
    'pacienteEdit.pais'(pais){
        this.getRegionesActivas(pais)
    },
    'pacienteEdit.region'(region){
        this.getComunasActivas(region)
    },
    },
    mounted () {
        this.getCuadrantes();
        this.getPaisesActivos();
        this.pacienteEdit = {
            id: this.pacienteData?.id ? this.pacienteData?.id : '',
            name: this.pacienteData?.name ? this.pacienteData?.name : '',
            rut: this.pacienteData?.info?.rut ? format(this.pacienteData?.info?.rut) : '',
            nacimiento: this.pacienteData?.info?.nacimiento ? this.pacienteData?.info?.nacimiento : '',
            email: this.pacienteData?.email ? this.pacienteData?.email : '',
            telefono: this.pacienteData?.info?.telefono ? this.pacienteData?.info?.telefono: '',
            region: this.pacienteData?.info?.region ? this.pacienteData?.info?.region : '',
            comuna: this.pacienteData?.info?.comuna_data?.comuna ? this.pacienteData?.info?.comuna_data?.comuna : '',
            direccion: this.pacienteData?.info?.direccion ? this.pacienteData?.info?.direccion : '',
            pais: 81, /* this.pacienteData?.info?.pais ? this.pacienteData?.info?.pais : '', */
            region: this.pacienteData?.info?.region ? this.pacienteData?.info?.region : '',
            comuna: this.pacienteData?.info?.comuna ? this.pacienteData?.info?.comuna : '',
            nacimiento: this.pacienteData?.info?.nacimiento ? this.pacienteData?.info?.nacimiento : '',
            genero: this.pacienteData?.info?.genero ? this.pacienteData?.info?.genero : '',
            cuadrante_id: this.pacienteData?.info?.cuadrante?.id ? this.pacienteData?.info?.cuadrante?.id : '',
            cluster: this.pacienteData?.info?.cluster ? this.pacienteData?.info?.cluster : []
        }   
    },
}
</script>

<style lang="scss" scoped>

</style>