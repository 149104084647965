<template>
    <div>
        <CRow class="mt-4">
            <CCol col="6">
                <label class="label">Antecedentes Mórbidos</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.medical_history" height="150" placeholder="Antecedentes Mórbidos"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Antecedentes Familiares</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.family_medical_history" placeholder="Antecedentes Familiares"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Antecedentes Personales</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.personal_medical_history" placeholder="Antecedentes Personales"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Hospitalizaciones</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.surgical_history_hospitalizations" placeholder="Hospitalizaciones"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Antecedentes Psiquiátricos</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.psychiatric_history" placeholder="Antecedentes Psiquiátricos"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Alergias</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.allergies" placeholder="Alergias"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Inmunizaciones</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.immunizations" placeholder="Inmunizaciones"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Medicamentos de uso habitual</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.regular_medications" placeholder="Medicamentos de uso habitual"></CTextarea>
            </CCol>
            <CCol col="6">
                <label class="label">Información personal</label>
                <CTextarea :disabled="disabled" v-model="last_medical_record.personal_information" placeholder="Estado civil , ocupación, red de apoyo, creencia"></CTextarea>
            </CCol>
        </CRow>

        <h5>Representante Legal</h5>
        <CRow>
            <CCol col="6">
                <label class="label">Nombre</label>
                <CInput :disabled="disabled" v-model="last_legal_guardian.legal_guardian_name" placeholder="Nombre"></CInput>
            </CCol>
            <CCol col="6">
                <label class="label">Rut</label>
                <CInput :disabled="disabled" v-model="last_legal_guardian.legal_guardian_rut" placeholder="Rut"></CInput>
            </CCol>
        </CRow>
        <div v-if="!disabled" class="d-flex" style="gap: 4px;">
        <CButton :disabled="disableCreate" style="width: 150px;"  @click="createMedicalRecord(); createUserGuardian();" color="primary">
            <CSpinner v-if="loading" color="white" size="sm" />
            <p v-else class="mb-0">Guardar</p>
        </CButton>
            <CAlert style="margin-bottom: 0" v-if="alertSuccess" color="success">Registro guardado correctamente</CAlert>
        </div>
    </div>
</template>

<script>
import { createMedicalRecord, getLastUserMedicalRecord, createUserGuardian } from '../../api/atenciones'

export default {
    props: ['user_id', 'disabled'],
    data () {
        return {
            last_medical_record: {
                medical_history: '',
                personal_medical_history: '',
                family_medical_history: '',
                surgical_history_hospitalizations: '',
                psychiatric_history: '',
                allergies: '',
                regular_medications: '',
                immunizations: '',
                personal_information: ''
            },
            last_legal_guardian: {
                legal_guardian_name: '',
                legal_guardian_rut: ''
            },
            loading: false,
            alertSuccess: false
        }
    },
    methods: {
        createMedicalRecord() {
            this.loading = true;
            const data = {...this.last_medical_record}
            data.user_id = this.user_id
            createMedicalRecord(data).then(res => {
                this.loading = false;
                this.getLastUserMedicalRecord()
                this.alertSuccess = true;
                setTimeout(() => {
                    this.alertSuccess = false;
                }, 1000);
            }).catch(e => {
                this.loading = false;
                console.log(e);
            })
        },
        getLastUserMedicalRecord() {
            const data = {user_id: this.user_id}
            getLastUserMedicalRecord(data).then(res => {
                if (res.last_medical_record) {
                    this.last_medical_record = {
                        medical_history: res.last_medical_record.medical_history,
                        personal_medical_history: res.last_medical_record.personal_medical_history,
                        family_medical_history: res.last_medical_record.family_medical_history,
                        surgical_history_hospitalizations: res.last_medical_record.surgical_history_hospitalizations,
                        psychiatric_history: res.last_medical_record.psychiatric_history,
                        allergies: res.last_medical_record.allergies,
                        regular_medications: res.last_medical_record.regular_medications,
                        immunizations: res.last_medical_record.immunizations,
                        personal_information: res.last_medical_record.personal_information,
                    }
                } 
                if (res.last_legal_guardian) {
                    this.last_legal_guardian = {
                        legal_guardian_name: res.last_legal_guardian.name,
                        legal_guardian_rut: res.last_legal_guardian.rut
                    }
                }
            })
        },
        createUserGuardian() {
            const data = {user_id: this.user_id, legal_guardian_name: this.last_legal_guardian.legal_guardian_name, legal_guardian_rut: this.last_legal_guardian.legal_guardian_rut }
            createUserGuardian(data).then(res => {
                console.log(res);
            })
        }
    },
    computed: {
        disableCreate() {
            if (!this.last_medical_record.medical_history &&  !this.last_medical_record.personal_medical_history && !this.last_medical_record.family_medical_history &&
                !this.last_medical_record.surgical_history_hospitalizations && !this.last_medical_record.psychiatric_history && !this.last_medical_record.allergies && 
                !this.last_medical_record.regular_medications && !this.last_medical_record.immunizations && !this.last_medical_record.personal_information 
                && !this.last_legal_guardian.legal_guardian_name && !this.last_legal_guardian.legal_guardian_rut) {
                return true
            } else {
                return false
            }
    }
  },
    mounted () {
        this.getLastUserMedicalRecord();
    },
}
</script>

<style lang="scss" scoped>
.label {
    font-weight: 700;
}
</style>